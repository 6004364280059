import { Injectable } from "@angular/core";
import { of, Observable } from "rxjs";
import { Litter } from "./litter";

@Injectable({
    providedIn: 'root'
})
export class LitterService {
    private litters: {[id: string]: Litter };

    constructor() {
        this.litters = {
            'a': {
                letter: 'a',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Flitters%2Fa.jpg?alt=media&token=c281fa40-4670-47b6-89bf-9f1f8d4eb2ad',
                pedigree: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fpedigrees%2Flitter-a.jpg?alt=media&token=d42b2500-756c-490d-9639-316e7809e321',
                born: new Date(2013, 5, 27),
                mother: {
                    name: 'Jane',
                    fullName: 'Tsar-ping\'s A Secret Jane',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fjane.jpg?alt=media&token=09d06d64-17c8-4e65-a940-80137d174486',
                },
                father: {
                    name: 'Gringo',
                    fullName: 'INT NORD FI UCH JVV-12 DKV-12-13 NORDV-12 NV-13 NV-14-15 EUW-15 Dalylove Google Me Gringo',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fgringo.jpg?alt=media&token=65ac96b2-bca5-49bb-bb7a-3fb12c1ac248',
                },
                numberOfMales: 1,
                numberOfFemales: 3,
            },
            'b': {
                letter: 'b',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Flitters%2Fb.jpg?alt=media&token=3d7ebd7c-457e-46f1-8b57-bda83363798b',
                pedigree: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fpedigrees%2Flitter-b.jpg?alt=media&token=6a13f69a-c2ff-4b48-a11d-5ffc204c126a',
                born: new Date(2015, 10, 13),
                mother: {
                    name: 'Jane',
                    fullName: 'Tsar-ping\'s A Secret Jane',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fjane2.jpg?alt=media&token=36ac34b6-c5d3-44c9-87be-b7ae13698c55',
                },
                father: {
                    name: 'Emil',
                    fullName: 'C.I.B. NORD UCH Email To Tsar Ping\'s Aluzja',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Femil.jpg?alt=media&token=e8c7b830-cceb-4db4-9416-f82dd87c8607',
                },
                numberOfMales: 2,
                numberOfFemales: 1,
            },
            'c': {
                letter: 'c',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Flitters%2Fc.jpg?alt=media&token=8fde10fb-3614-4bca-b7b7-a3ea1ec4d788',
                pedigree: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fpedigrees%2Flitter-c.jpg?alt=media&token=2bbc01e5-6612-4cc1-81ef-e6e950813b52',
                born: new Date(2016, 6, 22),
                mother: {
                    name: 'Mocca',
                    fullName: 'NORD UCH BLACK AMICS Amarone the hot Mocca',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fmocca2.jpg?alt=media&token=47b1dfdc-f8ed-4698-b748-83093b906216',
                },
                father: {
                    name: 'Monty',
                    fullName: 'Eaglespur Mandolin',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fmonty.jpg?alt=media&token=99cb5d87-ac32-4f31-a89d-07068e8b03ca',
                },
                numberOfMales: 3,
                numberOfFemales: 2,
            },
            'd': {
                letter: 'd',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Flitters%2Fd.jpg?alt=media&token=e26191aa-48c8-44c6-9c67-6e60412000ec',
                pedigree: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fpedigrees%2Flitter-d.jpg?alt=media&token=8f857fc2-e5ab-4ddb-88d6-b2b91870e73f',
                born: new Date(2018, 4, 4),
                mother: {
                    name: 'Mocca',
                    fullName: 'NORD UCH BLACK AMICS Amarone the hot Mocca',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fmocca2.jpg?alt=media&token=47b1dfdc-f8ed-4698-b748-83093b906216',
                },
                father: {
                    name: 'Stone',
                    fullName: 'AU N DK UCH Castlecairn Master Craftman',
                    image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fstone.jpg?alt=media&token=bddeafea-c772-4d34-b1d6-1b91bbe83028',
                },
                numberOfMales: 0,
                numberOfFemales: 5,
            },
        };
    }

    public getLitter(litter: string): Observable<any> {
        return of(this.litters[litter]);
    }

    public getAllLitters(): Observable<any[]> {
        let litters = [];

        for (const litter in this.litters) {
            litters.push(this.litters[litter]);
        }

        return of(litters);
    }
}
