import { Component, OnInit } from '@angular/core';
import { LitterService } from 'src/app/components/shared/litter.service';

@Component({
    selector: 'app-puppy-litters',
    templateUrl: './puppy-litters.component.html',
    styleUrls: ['./puppy-litters.component.css']
})
export class PuppyLittersComponent implements OnInit {
    private litters: any[];

    constructor(private litterService: LitterService) {
        this.litters = [];
    }

    ngOnInit() {
        this.litterService.getAllLitters().subscribe(litters => {
            this.litters = litters;
        });
    }
}
