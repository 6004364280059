import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactInformationComponent } from './pages/contact-information/contact-information.component';
import { AppRoutingModule } from './app-routing.module';
import { OurDogsComponent } from './pages/our-dogs/our-dogs.component';
import { PuppyLittersComponent } from './pages/puppy-litters/puppy-litters.component';
import { InMemoryOfComponent } from './pages/in-memory-of/in-memory-of.component';
import { BreedStandardComponent } from './pages/breed-standard/breed-standard.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarouselModule } from 'ngx-bootstrap';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FormsModule } from '@angular/forms';
import { LitterComponent } from './pages/puppy-litters/litter.component';
import { PuppiesComponent } from './pages/puppies/puppies.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ContactInformationComponent,
        OurDogsComponent,
        PuppyLittersComponent,
        InMemoryOfComponent,
        BreedStandardComponent,
        NavbarComponent,
        CarouselComponent,
        LitterComponent,
        PuppiesComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        CarouselModule,
        AppRoutingModule
    ],
    providers: [],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
