import { Injectable } from '@angular/core';
import { Dog } from './dog';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DogService {
    private currentDogs: Dog[];
    private previousDogs: Dog[];

    constructor() {
        this.currentDogs = [
            {
                name: 'Mocca',
                fullName: 'NORD UCH Black Amics Amarone The Hot Mocca',
                description: 'Mocca er født 27.06.2013 og er fra Jane sitt første kull. Hun er en nydelig og livlig jente med masse personlighet og livsglede. Hun har gjort det bra i utstillingsringen og er mor til C og D kullet.  Hun har vært en fantastisk mamma til valpene sine. Vi håper vi får mange flere år med henne i livet vårt. Mocca bor sammen med sin datter Chili og Tove og Olliss.',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fmocca.jpg?alt=media&token=6b53a9ad-a9b8-421b-94b2-8dc7d3ee5059',
            },
            {
                name: 'Chili',
                fullName: 'Black Amics Chili',
                description: 'Chili er født 22.07.2016 og er fra Mocca sitt første kull. Chili har debutert i utstillingsringen med masse bra kritikker. Hun har vært litt utrygg og ikke helt ønsket vise seg frem, men i fjor sommer knekket hun koden. Vi se hva tiden fremover bringer. Hun er en jente med masse spennende personlighet, og viste en herlig omsorg for sine små søsken i D kullet, så planen er at hun skal få sitt førte kull 2019/2020. Chili bor sammen med mamma Mocca, Tove og Olliss.',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fchili.jpg?alt=media&token=ba630a14-22cb-4bb6-bf95-efccba7aaf68',
            },
            {
                name: 'Zuma',
                fullName: 'Mirox Harmonie LaBelle',
                description: '28.04.2017 ble denne skjønne jenta Zuma født. Hun kommer fra Sverige og er av rasen Lancashire Heeler. Hun har full kontroll her i heimen. Hun er liten, livlig og full av futt og fart. Hun elsker familien sin og sier klart i fra om noen skulle komme for litt for nært hennes område. Zuma var en fantastisk tante til D kullet, og ønsker seg nok gjerne noen egne små med årene. Zuma bor sammen med Andrea og hennes familie.',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fzuma.jpg?alt=media&token=12273887-b084-4103-a4ce-57b0ff0b57c1',
            },
            // {
            //     name: 'Cade',
            //     fullName: 'Black Amics Casper',
            //     description: 'Til daglig går han under navnet Cade og er kullbror til Chili, født 22.07.2016. Han er på for og bor hos Anja sammen med 3 andre hunder. Han er en feminin liten herremann med ett herlig gemytt. Han elsker alle hunder bare han får leke.',
            //     image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fcade.jpg?alt=media&token=d8f58bf3-df51-4008-b591-781541b86a12',
            // },
        ];

        this.previousDogs = [
            {
                name: 'Jane',
                fullName: 'Tsar-ping’s A secret Jane',
                born: new Date(2010, 5, 28),
                died: new Date(2016, 1, 15),
                description: 'Jane kom til oss i september 2010. Hun var en fantastisk frøken som lærte oss masse, og hadde det ikke vært for henne hadde det nok heller ikke blitt en kennel Black Amic. Hun er mor til A og B kullene. Jane var en fantastisk mamma valpene sine. Dessverre ble hun veldig syk og måtte forlate oss så altfor tidlig. Vi savner henne hver dag, og er henne evig takknemlig for de fantastiske valpene hun har gitt oss. Mocca er en av disse.',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Fjane3.jpg?alt=media&token=16967683-985a-44e8-a314-3de615abae1a',
            },
            {
                name: 'Tarzan',
                fullName: 'X-Pected Dine Mites Vise Versa',
                born: new Date(2008, 11, 4),
                died: new Date(2017, 2, 3),
                description: 'Tarzan kom til oss februar 2009. Han var den aller første Manchester Terrieren for oss, og en absolutt fantastisk gutt. Dessverre hadde Tarzan problemer med Patella Lux og ble derfor aldri far til noen av våre kull. Han fikk også problemer med hofter og prollaps i ryggen som følge av patella, og måtte forlate oss altfor tidlig. Han satte dype spor i hjertet vårt og vil alltid bli husket og dypt savnet.',
                image: 'https://firebasestorage.googleapis.com/v0/b/kennel-black-amic.appspot.com/o/images%2Fdogs%2Ftarzan.jpg?alt=media&token=6f85d25d-67c3-4087-8e1f-5dc7802194e7',
            },
        ];
    }

    public getCurrentDogs(): Observable<Dog[]> {
        return of(this.currentDogs);
    }

    public getPreviousDogs(): Observable<Dog[]> {
        return of(this.previousDogs);
    }
}
