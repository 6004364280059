import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  public show: boolean;

  constructor() {
    this.show = false;
  }

  public toggleCollapse(): void {
    this.show = !this.show;
  }
  
  public closeCollapse(): void {
    this.show = false;
  }
}
