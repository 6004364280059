import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Litter } from 'src/app/components/shared/litter';
import { LitterService } from 'src/app/components/shared/litter.service';

@Component({
    selector: 'app-litter',
    templateUrl: './litter.component.html',
    styleUrls: ['./litter.component.css']
})
export class LitterComponent implements OnInit {
    private letter: string;
    private litter: Litter;

    constructor(private route: ActivatedRoute, private litterService: LitterService) { }

    ngOnInit() {
        this.letter = this.route.snapshot.paramMap.get('letter');
        this.litterService.getLitter(this.letter).subscribe(litter => {
            this.litter = litter;
        });
    }
}
