import { Component, OnInit } from '@angular/core';
import { Dog } from 'src/app/components/shared/dog';
import { DogService } from 'src/app/components/shared/dog.service';

@Component({
    selector: 'app-our-dogs',
    templateUrl: './our-dogs.component.html',
    styleUrls: ['./our-dogs.component.css']
})
export class OurDogsComponent implements OnInit {
    public dogs: Dog[];

    constructor(private dogService: DogService) {
        this.dogs = [];
    }

    ngOnInit() {
        this.dogService.getCurrentDogs().subscribe(dogs => {
            this.dogs = dogs;
        });
    }
}
