import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OurDogsComponent } from './pages/our-dogs/our-dogs.component';
import { InMemoryOfComponent } from './pages/in-memory-of/in-memory-of.component';
import { PuppiesComponent } from './pages/puppies/puppies.component';
import { PuppyLittersComponent } from './pages/puppy-litters/puppy-litters.component';
import { BreedStandardComponent } from './pages/breed-standard/breed-standard.component';
import { ContactInformationComponent } from './pages/contact-information/contact-information.component';
import { LitterComponent } from './pages/puppy-litters/litter.component';

const appRoutes: Routes = [
    { path: 'hjem', component: HomeComponent },
    { path: 'vare-hunder', component: OurDogsComponent },
    { path: 'til-minne', component: InMemoryOfComponent },
    { path: 'valper', component: PuppiesComponent },
    { path: 'valpekull', component: PuppyLittersComponent },
    { path: 'valpekull/:letter', component: LitterComponent },
    { path: 'rasestandard', component: BreedStandardComponent },
    { path: 'kontaktinformasjon', component: ContactInformationComponent },
    { path: '', redirectTo: 'hjem', pathMatch: 'full' },
    { path: '**', redirectTo: 'hjem' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
