import { Component, OnInit } from '@angular/core';
import { Dog } from 'src/app/components/shared/dog';
import { DogService } from 'src/app/components/shared/dog.service';

@Component({
    selector: 'app-in-memory-of',
    templateUrl: './in-memory-of.component.html',
    styleUrls: ['./in-memory-of.component.css']
})
export class InMemoryOfComponent implements OnInit {
    public dogs: Dog[];

    constructor(private dogService: DogService) {
        this.dogs = [];
    }

    ngOnInit() {
        this.dogService.getPreviousDogs().subscribe(dogs => {
            this.dogs = dogs;
        });
    }
}
